<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="make">Make</label>
            <v-select
              name="make"
              v-model="carAttributesParams.make"
              :reduce="(make) => make.make"
              class="w-full"
              :options="makers"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Makers"
              :searchable="true"
              label="make"
              @search="fetchMakeOptions"
              @input="onInputChanged('make')"
              v-validate="'required'"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('make')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span class="text-danger text-sm">{{ errors.first('make') }}</span>
        </div>
        
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="model">Model</label>
            <v-select
              name="model"
              v-model="carAttributesParams.model"
              :reduce="(family) => family.family"
              class="w-full"
              :options="models"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Model"
              :searchable="true"
              label="family"
              @search="fetchModelOptions"
              @input="onInputChanged('model')"
              v-validate="'required'"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('model')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span class="text-danger text-sm">{{ errors.first('model') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="year">Year</label>
            <v-select
              name="year"
              v-model="carAttributesParams.year"
              :reduce="(year) => year.year"
              class="w-full"
              :options="years"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Year"
              :searchable="true"
              label="year"
              @search="fetchYearOptions"
              @input="onInputChanged('year')"
              v-validate="'required'"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('year')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span class="text-danger text-sm">{{ errors.first('year') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="badge">Badge</label>
            <v-select
              name="badge"
              v-model="carAttributesParams.badge"
              :reduce="(badge) => badge.badge"
              class="w-full"
              :options="badges"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Badge"
              :searchable="true"
              label="badge"
              @search="fetchBadgeOptions"
              @input="onInputChanged('badge')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('badge')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="series">Series</label>
            <v-select
              name="series"
              v-model="carAttributesParams.series"
              :reduce="(series) => series.series"
              class="w-full"
              :options="seriesList"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Series"
              :searchable="true"
              label="series"
              @search="fetchSeriesOptions"
              @input="onInputChanged('series')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('series')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="bodyType">Body Type</label>
            <v-select
              name="bodyType"
              v-model="carAttributesParams.bodyType"
              :reduce="(bodyType) => bodyType.bodyType"
              class="w-full"
              :options="bodyTypes"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Body"
              :searchable="true"
              label="bodyType"
              @search="fetchBodyTypeOptions"
              @input="onInputChanged('bodyType')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('bodyType')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualBodyTypeError" style="text-align:center;" class="text-danger text-sm">{{manualBodyTypeError}}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="transmission">Transmission</label>
            <v-select
              name="transmission"
              v-model="carAttributesParams.transmission"
              :reduce="(transmission) => transmission.transmission"
              class="w-full"
              :options="transmissions"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Transmission"
              :searchable="true"
              label="transmission"
              @search="fetchTransmissionOptions"
              @input="onInputChanged('transmission')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('transmission')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualTransmissionError" style="text-align:center;" class="text-danger text-sm">{{manualTransmissionError}}</span>
        </div>
      </vs-col>

       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="engineCapacity">Engine Capacity</label>
            <v-select
              name="carEngine"
              v-model="carAttributesParams.engineCapacity"
              :reduce="(engineCapacity) => engineCapacity.engineCapacity"
              class="w-full"
              :options="engineCapacities"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Engine"
              :searchable="true"
              label="engineCapacity"
              @search="fetchEngineCapacityOptions"
              @input="onInputChanged('engineCapacity')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('engineCapacity')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualEngineCapacityError" style="text-align:center;" class="text-danger text-sm">{{manualEngineCapacityError}}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cylinder">Cylinder</label>
            <v-select
              name="carCylinder"
              v-model="carAttributesParams.cylinder"
              :reduce="(cylinder) => cylinder.cylinder"
              class="w-full"
              :options="cylinders"
              :clearable="true"
              :filterable="false"
              placeholder="Select Car Cylinder"
              :searchable="true"
              label="cylinder"
              @search="fetchCylinderOptions"
              @input="onInputChanged('cylinder')"
            >
            <li slot="list-footer">
              <div @click="openManualEntryModal('cylinder')" style="cursor:pointer; margin-left:20px">Other</div>
            </li>
          </v-select>
          <span v-if="manualCylinderError" style="text-align:center;" class="text-danger text-sm">{{manualCylinderError}}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationState">Registration State</label>
            <v-select
              name="state"
              v-model="carDetail.registrationState"
              :reduce="(stateName) => stateName.stateName"
              class="w-full"
              :options="states"
              :clearable="false"
              :filterable="false"
              placeholder="Select Car Registration State"
              :searchable="true"
              label="stateName"
              v-validate="'required'"
            >
            </v-select>
            <span class="text-danger text-sm">{{ errors.first('state') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="kilometer">Kilometers</label>
           <vs-input
              name="kilometer"
              v-validate="'required'"
              data-vv-validate-on="blur"
              type="number"
              v-model="carDetail.kilometer"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first('kilometer') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="askingPrice">Asking Price</label>
            <vs-input
              name="askingPrice"
              type="number"
              v-validate="'required'"
              data-vv-validate-on="blur"
              v-model="carDetail.askingPrice"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first('askingPrice') }}</span>
        </div>
      </vs-col>
      
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" v-if="carDetail._id">
        <div class="w-full m-5">
          <label for="driveType">Drive Type</label>
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required'"
            name="driveType"
            v-model="carDetail.drv"
            disabled
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('driveType') }}</span>
        </div>
      </vs-col>
           
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="interiorColor">Interior Color</label>
          <vs-input
            name="interiorColor"
            v-validate="'required'"
            data-vv-validate-on="blur"
            v-model="carDetail.interiorColor"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('interiorColor') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="exteriorColor">Exterior Color</label>
          <vs-input
            name="exteriorColor"
            v-validate="'required'"
            data-vv-validate-on="blur"
            v-model="carDetail.exteriorColor"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('exteriorColor') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="vinNumber">VIN Number</label>
          <vs-input
            v-validate=""
            data-vv-validate-on="blur"
            name="vinNumber"
            v-model="carDetail.vinNumber"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('vinNumber') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationExpiryDate">Registration Expiry Date</label>
         <flat-pickr
            v-model="carDetail.registrationExpiryDate"
            class="w-full"
            name="registrationExpiryDate"
          />
          <span class="text-danger text-sm">{{ errors.first('registrationExpiryDate') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="licensePlate">License Plate</label>
          <vs-input
            v-validate="'min:3'"
            data-vv-validate-on="blur"
            name="licensePlate"
            v-model="carDetail.licencePlate"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('licensePlate') }}</span>
        </div>
      </vs-col>

       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8">
        <div class="w-full m-5">
          <label for="description">Description</label>
          <vs-textarea
            name="description"
            v-model="carDetail.description"
            v-validate="'required|min:5'"
            data-vv-validate-on="blur"
            class="w-full"
          />
          <span class="text-danger text-sm">{{ errors.first('description') }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="registrationUptoDate">Registration Upto Date?</label>
          <vs-checkbox v-model="carDetail.isRegistrationUptoDate"></vs-checkbox>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="hasWrittenOff">Has Written Off?</label>
          <vs-checkbox v-model="carDetail.hasWrittenOff"></vs-checkbox>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="fullServiceAvailable">Full Service Available?</label>
          <vs-checkbox v-model="carDetail.fullServiceAvailable"></vs-checkbox>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="isDraft">Is Draft?</label>
          <vs-checkbox v-model="carDetail.isDrafted"></vs-checkbox>
        </div>
      </vs-col>
     
      
      <vs-col>
        <div class="mb-3"
        id="my-strictly-unique-vue-upload-multiple-image"
        style="display: flex; justify-content: center; margin-top: 30px;"
        v-if="!carDetail._id"
      >
        <vue-upload-multiple-image
          @upload-success="uploadImageSuccess"
          @before-remove="beforeRemove"
          :showEdit="false"
          :dataImages="dataPhotos"
          browseText="Upload Car Images"
          dragText
          :showPrimary="false"
          :maxImage="30"
        ></vue-upload-multiple-image>
        
      </div>
      <span v-if="imageUploadError" style="text-align:center;" class="text-danger text-sm">{{
        imageUploadError
      }}</span>
      </vs-col>
      <vs-row vs-justify="flex-end">
        <!-- <vs-button color="success" v-if="carDetail._id" @click="updateCarDetail">Update</vs-button> -->
        <vs-button color="success" v-if="!carDetail._id" @click="addNewCarDetail">Save</vs-button>
        <vs-button
          color="danger"
          style="margin-left:5px;"
          @click="$router.replace(`/admin/car-dealer/${dealerId}`)"
        >Cancel</vs-button>
      </vs-row>
    </vs-row>
    <carDataManualModal v-if="openManualCarDataModel" :attribute="carAttributeType" @prop-car-update="closeCarManualModal"/>
  </vx-card>
</template>

  <script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "../../http/axios";
import vSelect from "vue-select";
import carDataManualModal from "./carDataManualModal";

export default {
  name: "carDetails",
  components: {
    flatPickr,
    VueUploadMultipleImage,
    "v-select": vSelect,
    carDataManualModal,
  },

  data: () => ({
    activeTab: 0,
    dealerId: "",
    carId: "",
    carDetail: {
      licensePlate: "",
      make: "",
      model: "",
      transmission: "",
      bodyType: "",
      isDrafted: false,
      interiorColor: "",
      exteriorColor: "",
      year: "",
      askingPrice: "",
      description: "",
      kilometer: "",
      series: "",
      badge: "",
      engineCapacity: "",
      cylinder: "",
      vinNumber: "",
      registrationState: "",
      isRegistrationUptoDate: false,
      registrationExpiryDate: "",
      hasWrittenOff: false,
      fullServiceAvailable: false,
      images: [],
    },
    photos: [],
    dataPhotos: [],
    dataImg: "",
    imageUploadError: "",
    states: [],
    makers: [],
    models: [],
    years: [],
    badges: [],
    seriesList: [],
    bodyTypes: [],
    transmissions: [],
    engineCapacities: [], 
    cylinders: [], 
    kilometers: [],
    prices: [],
    timeout: null,
    isManual: false,
    openManualCarDataModel: false,
    carAttributeType: "",
    isMakeManual: false,
    isModelManual: false,
    isYearManual: false,
    isBadgeManual: false,
    isSeriesManual: false,
    isBodyTypeManual: false,
    isTransmissionManual: false,
    isEngineCapacityManual: false,
    isCylinderManual: false,
    manualBodyTypeError: "",
    manualTransmissionError: "",
    manualEngineCapacityError: "",
    manualCylinderError: "",
    carAttributesParams : {
      after: "",
      page: 1,
      limit: 20,
      search: "",
      make: "",
      model: "",
      year: "",
      badge: "",
      series: "",
      bodyType: "",
      transmission: "",
      engineCapacity: "",
      cylinder: "",
    }
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any()
      );
    },
    isCarFieldManual() {
      if(this.checkForManual && !this.carAttributesParams.bodyType && !this.carAttributesParams.transmission && !this.carAttributesParams.engineCapacity && !this.carAttributesParams.cylinder) {
        console.log('first check')
        return false;
      } else if(this.checkForManual && !this.isBodyTypeManual && !this.isTransmissionManual && !this.isEngineCapacityManual && !this.isCylinderManual) {
        console.log('second check')
        return false;
      }
      return (
        this.isMakeManual || this.isModelManual || this.isYearManual || this.isBadgeManual || this.isSeriesManual || this.isBodyTypeManual || this.isTransmissionManual || this.isEngineCapacityManual || this.isCylinderManual
      )
    },
    checkForManual() {
      return (
        !this.isMakeManual && !this.isModelManual && !this.isYearManual && !this.isBadgeManual && !this.isSeriesManual
      )
    },
    carAttributesParamsMake() {
      if(!this.carAttributesParams.make) return "";
      return this.carAttributesParams.make;
    },
    carAttributesParamsModel() {
      if(!this.carAttributesParams.model) return "";
      return this.carAttributesParams.model;
    },
    carAttributesParamsYear() {
      if(!this.carAttributesParams.year) return "";
      return this.carAttributesParams.year;
    },
    carAttributesParamsBadge() {
      if(!this.carAttributesParams.badge) return "";
      return this.carAttributesParams.badge;
    },
    carAttributesParamsSeries() {
      if(!this.carAttributesParams.series) return "";
      return this.carAttributesParams.series;
    },
    carAttributesParamsBodyType() {
      if(!this.carAttributesParams.bodyType) return "";
      return this.carAttributesParams.bodyType;
    },
    carAttributesParamsTransmission() {
      if(!this.carAttributesParams.transmission) return "";
      return this.carAttributesParams.transmission;
    },
    carAttributesParamsEngineCapacity() {
      if(!this.carAttributesParams.engineCapacity) return "";
      return this.carAttributesParams.engineCapacity;
    },
    carAttributesParamsCylinder() {
      if(!this.carAttributesParams.cylinder) return "";
      return this.carAttributesParams.cylinder;
    },

  },
  methods: {
    ...mapActions("carDealer", ["updateDealerCar", "addNewCar", "carMakerList", "carModels", "carYearList", "carBadgeList", "carSeriesList", "carBodyTypeList", "carTransmissionList", "carEngineCapacity", "carCylinders", "statesList", "priceRange", "kilometerRange"]),
    ...mapActions("admin", ["carDetails"]),

    onInputChanged(type) {
      switch(type) {
          case "make":
            this.isMakeManual = false;
            this.carDetail.makeManual = false;
            break;
          case "model":
            this.isModelManual = false;
            this.carDetail.modelManual = false;
            break;
          case "year":
            this.isYearManual = false;
            this.carDetail.yearManual = false;
            break;
          case "badge":
            this.isBadgeManual = false;
            this.carDetail.badgeManual = false;
            break;
          case "series":
            this.isSeriesManual = false;
            this.carDetail.seriesManual = false;
            break;
          case "bodyType":
            this.isBodyTypeManual = this.isCarFieldManual ? true : false;
            this.manualBodyTypeError = "";
            break;
          case "transmission":
            this.isTransmissionManual = this.isCarFieldManual ? true : false;
            this.manualTransmissionError = "";
            break;
          case "engineCapacity":
            this.isEngineCapacityManual = this.isCarFieldManual ? true : false;
            this.manualEngineCapacityError = "";
            break;
          case "cylinder":
            this.isCylinderManual = this.isCarFieldManual ? true : false;
            this.manualCylinderError = "";
            break;
        }
    },

    openManualEntryModal(attribute){
      this.carAttributeType = attribute;
      this.openManualCarDataModel = true;
    },
    closeCarManualModal(value) {
      this.carAttributeType = "";
      this.openManualCarDataModel = false;
      if(value) {
        let carKey = Object.keys(value)[0]
        switch(carKey) {
          case "make":
            this.carDetail.make = value[carKey];
            this.carAttributesParams.make = value[carKey];
            this.isMakeManual = true;
            this.carDetail.makeManual = true;
            break;
          case "model":
            this.carDetail.model = value[carKey];
            this.carAttributesParams.model = value[carKey];
            this.isModelManual = true;
            this.carDetail.modelManual = true;
            break;
          case "year":
            this.carDetail.year = value[carKey];
            this.carAttributesParams.year = value[carKey];
            this.isYearManual = true;
            this.carDetail.yearManual = true;
            break;
          case "badge":
            this.carDetail.badge = value[carKey];
            this.carAttributesParams.badge = value[carKey];
            this.isBadgeManual = true;
            this.carDetail.badgeManual = true;
            break;
          case "series":
            this.carDetail.series = value[carKey];
            this.carAttributesParams.series = value[carKey];
            this.isSeriesManual = true;
            this.carDetail.seriesManual = true;
            break;
          case "bodyType":
            this.carDetail.bodyType = value[carKey];
            this.carAttributesParams.bodyType = value[carKey];
            this.isBodyTypeManual = true;
            this.carDetail.bodyTypeManual = true;
            break;
          case "transmission":
            this.carDetail.transmission = value[carKey];
            this.carAttributesParams.transmission = value[carKey];
            this.isTransmissionManual = true;
            this.carDetail.transmissionManual = true;
            break;
          case "engineCapacity":
            this.carDetail.engineCapacity = value[carKey];
            this.carAttributesParams.engineCapacity = value[carKey];
            this.isEngineCapacityManual = true;
            this.carDetail.engineCapacityManual = true;
            break;
          case "cylinder":
            this.carDetail.cylinder = value[carKey];
            this.carAttributesParams.cylinder = value[carKey];
            this.isCylinderManual = true;
            this.carDetail.cylinderManual = true;
            break;
        }
      }
    },

    fetchMakeOptions(search, loading) {
      if(this.timeout){
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarMakers(searchField);
      },500)
    },

    fetchModelOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarModels(searchField);
      },500)
    },

    fetchYearOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarYears(searchField);
      },500)
    },
    
    fetchBadgeOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarBadges(searchField);
      },500)
    },

    fetchSeriesOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarSeries(searchField);
      },500)
    },

    fetchBodyTypeOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarBodyType(searchField);
      },500)
    },

    fetchTransmissionOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarTransmission(searchField);
      },500)
    },

    fetchEngineCapacityOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarEngineCapacity(searchField);
      },500)
    },

    fetchCylinderOptions(search, loading) {
      if(this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        let searchField = search ? search : "";
        this.getCarCylinders(searchField);
      },500)
    },

    getStates(){
      let self = this;
      this.statesList(self.carAttributesParams).then((res) => {
        self.states = res.data.data.docs;
      })
    },
    getCarMakers(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.limit = 200;
      this.carMakerList(self.carAttributesParams).then((res) => {
        self.makers = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
      })
    },

    getCarModels(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carModels(self.carAttributesParams).then((res) => {
        self.models = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
      })
    },

    getCarYears(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carYearList(self.carAttributesParams).then((res) => {
        self.years = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.years.length === 0) this.getCarBadges();
      })
    },

    getCarBadges(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carBadgeList(self.carAttributesParams).then((res) => {
        self.badges = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.badges.length === 0) this.getCarSeries();
      })
    },
    
    getCarSeries(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      this.carSeriesList(self.carAttributesParams).then((res) => {
        self.seriesList = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.seriesList.length === 0) this.getCarBodyType();
      })
      
    },

    getCarBodyType(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isBodyTypeManual = self.isCarFieldManual ? true : false;
      self.carDetail.bodyTypeManual = self.isBodyTypeManual ? true : false;
      this.carBodyTypeList(self.carAttributesParams).then((res) => {
        self.bodyTypes = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.bodyTypes.length === 0) this.getCarTransmission();
      })
    },

    getCarTransmission(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isTransmissionManual = self.isCarFieldManual ? true : false;
      self.carDetail.transmissionManual = self.isTransmissionManual ? true : false;
      this.carTransmissionList(self.carAttributesParams).then((res) => {
        self.transmissions = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = search;
        if(self.transmissions.length === 0) this.getCarEngineCapacity();
      })
    },

    getCarEngineCapacity(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isEngineCapacityManual = self.isCarFieldManual ? true : false;
      self.carDetail.engineCapacityManual = self.isEngineCapacityManual ? true : false;
      this.carEngineCapacity(self.carAttributesParams).then((res) => {
        self.engineCapacities = res.data.data.buckets;
        // self.carAttributesParams.after = res.data.data.after_key;
        self.carAttributesParams.search = "";
        if(self.engineCapacities.length === 0) this.getCarCylinders();
      })
    },
    
    getCarCylinders(search=""){
      let self = this;
      self.carAttributesParams.search = search;
      self.carAttributesParams.isCylinderManual = self.isCarFieldManual ? true : false;
      self.carDetail.cylinderManual = self.isCylinderManual ? true : false;
      this.carCylinders(self.carAttributesParams).then((res) => {
        self.cylinders = res.data.data.buckets;
        self.carAttributesParams.search = "";
        // self.carAttributesParams.after = res.data.data.after_key;
      })
    },

    getKilometerRange(){
      let self = this;
      this.kilometerRange(self.carAttributesParams).then((res) => {
        self.kilometers = res.data.data;
      })
    },

    getPriceRange(){
      let self = this;
      this.priceRange(self.carAttributesParams).then((res) => {
        self.prices = res.data.data;
      })
    },

    uploadImageSuccess(formData, index, fileList,) {
      this.imageUploadError = "";
      this.photos.push(fileList[index]);
    },

    beforeRemove(index, done, fileList) {
      this.photos.splice(index,1)
      done();
    },
    async uploadImage(carImage){
      return await axios
        .post(
        process.env.VUE_APP_API_URL + "admin/vehicle/uploadImage",
        {carImage})
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
        console.log("image upload error", err);
        });
    },
    getCarDetail(id) {
      let self = this;
      this.carDetails(id).then((res) => {
        self.carDetail = res.data.data;
      });
    },
    updateCarDetail() {
      this.updateDealerCar(this.carDetail).then((res) => {
        this.$vs.notify({
          title: "Car Updated",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push(`/admin/car-dealer/${res.data.data.dealerId}`);
      });
    },

    async addNewCarDetail() {
      this.carDetail.dealerId = this.dealerId;
      if(this.photos.length < 1){
        this.imageUploadError = "At least one car image needs to be uploaded.";
      }

      this.manualBodyTypeError = "";
      this.manualTransmissionError = "";
      this.manualEngineCapacityError = "";
      this.manualCylinderError = "";

      //when car field is manual entry
      this.carDetail.bodyType = this.carAttributesParams.bodyType; 
      this.carDetail.transmission = this.carAttributesParams.transmission; 
      this.carDetail.engineCapacity = this.carAttributesParams.engineCapacity; 
      this.carDetail.cylinder = this.carAttributesParams.cylinder; 
      this.carDetail.cylinderManual = this.isCylinderManual; 
      
      this.$validator.validateAll().then(async(result) => {
        let isFormValid = true;

        if(this.isCarFieldManual) {
          this.manualBodyTypeError = !this.carDetail.bodyType ? "Please enter this field": "";
          this.manualTransmissionError = !this.carDetail.transmission ? "Please enter this field": "";
          this.manualEngineCapacityError = !this.carDetail.engineCapacity ? "Please enter this field": "";
          this.manualCylinderError = !this.carDetail.cylinder ? "Please enter this field": "";
        }
        if (this.manualBodyTypeError || this.manualTransmissionError || this.manualEngineCapacityError || this.manualCylinderError) {
          isFormValid = false;
        }
        
        if(this.validateForm && isFormValid && this.photos.length > 0){
          this.$vs.loading();
          for( let photo of this.photos ) {
            let uploadImage = await this.uploadImage(photo.path);
            if(uploadImage && uploadImage.Location){
              this.carDetail.images.push({imageUrl:uploadImage.Location});
              this.imageUploadError = "";
            } else {
              this.$vs.loading.close();
              this.imageUploadError = "Image upload failed, try again";
            }
          }
        }
        if (this.imageUploadError) {
          isFormValid = false;
        }
        if (result && isFormValid && this.validateForm) {
          this.$vs.loading();
          this.carDetail.isRegistrationUptoDate = this.carDetail.isRegistrationUptoDate ? "Yes" : "No";
          this.carDetail.hasWrittenOff = this.carDetail.hasWrittenOff ? "Yes" : "No";
          this.carDetail.fullServiceAvailable = this.carDetail.fullServiceAvailable ? "Yes" : "No";
          
          this.addNewCar(this.carDetail).then((res) => {
            this.$vs.loading.close();
            if (res.data.data) {
              this.$vs.notify({
                title: "Car Added",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push(
                `/admin/car-dealer/${res.data.data.dealerId}`
              );
              // this.getCarDetail(res.data.data._id);
            } else {
              //for email already i use
              this.$vs.notify({
                title: "Error",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          });
        }
      });
    },
  },
  watch: {
    "carAttributesParamsMake": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.carAttributesParams.make = newVal;
        this.carAttributesParams.model = "";
        this.carAttributesParams.year = "";
        this.carAttributesParams.badge = "";
        this.carAttributesParams.series = "";
        this.carDetail.make = newVal;
        this.carDetail.model = "";
        this.carDetail.year = "";
        this.carDetail.badge = "";
        this.carDetail.series = "";
        this.models = [];
        this.years = [];
        this.badges = [];
        this.seriesList = [];
        this.bodyTypes = [];
        this.transmissions = [];
        this.engineCapacities = [];
        this.cylinders = [];
        this.getCarModels();
        this.getCarYears();
        this.getCarSeries();
        this.getCarBadges();
        this.getCarBodyType();
        this.getCarTransmission();
        this.getCarEngineCapacity();
        this.getCarCylinders();
        this.carDetail.modelManual = false;
        this.carDetail.yearManual = false;
        this.carDetail.seriesManual = false;
        this.carDetail.badgeManual = false;
        this.isModelManual = false;
        this.isYearManual = false;
        this.isSeriesManual = false;
        this.isBadgeManual = false;
      }
    },
    "carAttributesParamsModel": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carAttributesParams.model = newVal;
          this.carAttributesParams.year = "";
          this.carAttributesParams.badge = "";
          this.carAttributesParams.series = "";
          this.carDetail.model = newVal;
          this.carDetail.year = "";
          this.carDetail.badge = "";
          this.carDetail.series = "";
          this.carDetail.bodyType = "";
          this.carDetail.transmission = "";
          this.carDetail.engineCapacity = "";
          this.carDetail.cylinder = "";
          this.years = [];
          this.badges = [];
          this.seriesList = [];
          this.bodyTypes = [];
          this.transmissions = [];
          this.engineCapacities = [];
          this.cylinders = [];
          this.getCarYears();
          this.getCarSeries();
          this.getCarBadges();
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
          this.carDetail.yearManual = false;
          this.carDetail.seriesManual = false;
          this.carDetail.badgeManual = false;
          this.isYearManual = false;
          this.isSeriesManual = false;
          this.isBadgeManual = false;
      }
    },
    "carAttributesParamsYear": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.carAttributesParams.year = newVal;
        this.carAttributesParams.badge = "";
        this.carAttributesParams.series = "";
        this.carDetail.year = newVal,
        this.carDetail.badge = "",
        this.carDetail.series = "",
        this.carDetail.bodyType = "",
        this.carDetail.transmission = "",
        this.carDetail.engineCapacity = "",
        this.carDetail.cylinder = "",
        this.badges = [];
        this.seriesList = [];
        this.bodyTypes = [];
        this.transmissions = [];
        this.engineCapacities = [];
        this.cylinders = [];
        this.getCarBadges();
        this.getCarSeries();
        this.getCarBodyType();
        this.getCarTransmission();
        this.getCarEngineCapacity();
        this.getCarCylinders();
        this.carDetail.seriesManual = false;
        this.carDetail.badgeManual = false;
        this.isSeriesManual = false;
        this.isBadgeManual = false;
        
      }
    },
    "carAttributesParamsBadge": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carAttributesParams.badge = newVal;
          this.carAttributesParams.series = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.badge = newVal,
          this.carDetail.series = "",
          this.seriesList = [];
          this.bodyTypes = [];
          this.transmissions = [];
          this.engineCapacities = [];
          this.cylinders = [];
          this.getCarSeries();
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
          this.carDetail.seriesManual = false;
          this.isSeriesManual = false;
      }
    },
    "carAttributesParamsSeries": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carAttributesParams.series = newVal;
          this.carDetail.series = newVal,
          this.bodyTypes = [];
          this.transmissions = [];
          this.engineCapacities = [];
          this.cylinders = [];
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        
      }
    },
    "carAttributesParamsBodyType": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carAttributesParams.bodyType = newVal;
          this.carAttributesParams.transmission = "";
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.bodyType = newVal,
          this.carDetail.transmission = "",
          this.carDetail.engineCapacity = "",
          this.carDetail.cylinder = "",
          this.transmissions = [];
          this.engineCapacities = [];
          this.cylinders = [];
          this.carDetail.transmissionManual = false;
          this.carDetail.engineCapacityManual = false;
          this.carDetail.cylinderManual = false;
          this.isTransmissionManual = false;
          this.isEngineCapacityManual = false;
          this.isCylinderManual = false;
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        
      }
    },
    "carAttributesParamsTransmission": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carAttributesParams.transmission = newVal;
          this.carAttributesParams.engineCapacity = "";
          this.carAttributesParams.cylinder = "";
          this.carDetail.transmission = newVal,
          this.carDetail.engineCapacity = "",
          this.carDetail.cylinder = "",
          this.engineCapacities = [];
          this.cylinders = [];
          this.carDetail.engineCapacityManual = false;
          this.carDetail.cylinderManual = false;
          this.isEngineCapacityManual = false;
          this.isCylinderManual = false;
          this.getCarEngineCapacity();
          this.getCarCylinders();
      }
    },
    "carAttributesParamsEngineCapacity": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carAttributesParams.engineCapacity = newVal;
          this.carAttributesParams.cylinder = "";
          this.carDetail.engineCapacity = newVal,
          this.carDetail.cylinder = "",
          this.cylinders = [];
          this.carDetail.cylinderManual = false;
          this.isCylinderManual = false;
          this.getCarEngineCapacity();
          this.getCarCylinders();
        
      }
    },

    "carAttributesParamsCylinder": function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.carDetail.cylinder = newVal;
          this.carAttributesParams.cylinder = newVal;
          this.carDetail.cylinderManual = true;
        }
    },

    "isCarFieldManual": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if(this.isCarFieldManual) {
          this.getCarBodyType();
          this.getCarTransmission();
          this.getCarEngineCapacity();
          this.getCarCylinders();
        }
      }
    },
    
  },
  created() {
    this.dealerId = this.$route.params.id;
    this.getCarMakers();
    this.getStates();
    // this.getKilometerRange();
    // this.getPriceRange();
    this.carId = this.$route.params.carId;
    if (this.carId) {
      this.getCarDetail(this.carId);
    }
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>  